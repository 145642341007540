<template>
  <label>
    <input type="checkbox" :value="value" v-on="listeners" />
    <Icon v-if="value" v-bind="$attrs">vb-checkmark-filled</Icon>
    <Icon v-else v-bind="$attrs">vb-checkmark</Icon>
  </label>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: v => {
          this.$emit("input", v);
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  display: none;
}

::v-deep .v-icon {
  cursor: pointer;
  color: $background;
}

::v-deep .vb-checkmark-filled {
  color: $primary;
}

label {
  color: $background;
}
</style>
