





























































import Vue from "vue";
import { mapState } from "vuex";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { BoardConfig } from "@/options/boardOptions";

export default Vue.extend({
  name: "Boards",
  data() {
    return {
      settingModal: false
    };
  },
  computed: {
    ...mapState("boards", [
      "boards",
      "currentBoard",
      "viewerBoards",
      "updateInProgress"
    ])
  },
  methods: {
    async onClick(board: BoardConfig) {
      this.$emit("onClick", board);
    },
    async deleteBoard(event: KeyboardEvent, board: BoardConfig) {
      event.preventDefault();
      event.stopPropagation();
      if (await this.$confirm("Are you sure?")) {
        await this.$store.dispatch("boards/deleteBoard", board.id);
        analyticsLogEvent(gaEventNames.board_deleted, {
          board_id: board.id,
          board_user_id: board.user
        });
        if (this.boards.length > 0) {
          const nextPath = `/boards/${this.boards[0].id}`;
          if (this.$route.path !== nextPath) this.$router.push(nextPath);
        } else {
          if (this.$route.name !== "Home") this.$router.push("/");
        }
      }
    },
    boardActive(board: BoardConfig) {
      return board && this.currentBoard && board.id === this.currentBoard.id;
    }
  }
});
