<template>
  <div>
    <slot
      :errors="{ errorMessages: activeErrorMessages }"
      :hasErrors="hasErrors"
    />
  </div>
</template>
<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
export default {
  name: "BaseFormGroup",
  extends: singleErrorExtractorMixin
};
</script>
