import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=7a628b7d&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=ts&"
export * from "./Menu.vue?vue&type=script&lang=ts&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=7a628b7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a628b7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VList,VListGroup,VListItem,VListItemGroup,VListItemTitle,VNavigationDrawer})
