import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { db } from "@/plugins/firebase";

export default Vue.extend({
  data() {
    return {
      owner: false,
      ownerId: ""
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    ...mapGetters("users", ["uid"])
  },
  methods: {
    async isOwner(boardId: string) {
      if (!boardId) return;

      const id =
        this.currentBoard && this.currentBoard?.id === boardId
          ? this.currentBoard.user
          : await db
              .collection("boards")
              .doc(boardId)
              .get()
              .then(res => {
                return res.data()?.user;
              });

      this.owner = id === this.uid;
      this.ownerId = id;
    }
  }
});
