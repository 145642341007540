export const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;

declare global {
  interface Window {
    Intercom: any;
    Cypress: any;
  }
}
const intercomWrapper = (fn: any) => {
  if (process.env.NODE_ENV === "production" && !window.Cypress) {
    return (...args: any) => {
      fn(args);
    };
  }

  console.log("Skipping intercom function", fn);
  return () => undefined;
};
// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
// prettier-ignore
export const loadIntercom = intercomWrapper(() => {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
})
// Initializes Intercom
export const bootIntercom = intercomWrapper((options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom("boot", { app_id: APP_ID, ...options });
});

export const toggleIntercomButton = intercomWrapper((args: any) => {
  const [shouldHide] = args;
  window &&
    window.Intercom &&
    window.Intercom("update", {
      hide_default_launcher: shouldHide
    });
});

// This method just calls Intercom('update'), which should be run on every page
// change. This does two things:
// 1. Send an update to Intercom to create an impression on the current URL
// 2. Fetch any messages that should be delivered based on the URL and user
export const updateIntercom = intercomWrapper(() => {
  window && window.Intercom && window.Intercom("update");
});

// Clears user session and unloads messages
export const shutdownIntercom = intercomWrapper(() => {
  window && window.Intercom && window.Intercom("shutdown");
});
