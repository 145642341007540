import moment from "moment";
import {
  RootState,
  User,
  UserAccount,
  UserSettings,
  UserState
} from "@/store/types";
import { analytics, auth, db } from "@/plugins/firebase";
import { bootIntercom, shutdownIntercom } from "@/plugins/intercom";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Sentry from "@/plugins/sentry";
import { firestoreAction } from "vuexfire";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import { notificationStatus } from "@/options/notificationOptions";
import * as gaEventNames from "@/options/analyticsOptions";
import router from "@/router/index";
import firebase from "firebase/app";
import { trackFbqEvent } from "@/fbq";
import {
  requiresEmailVerification,
  fbqPurchaseEventAlreadySent
} from "@/helpers/userHelpers";
import { identifyFS } from "@/plugins/fullstory";
import { getOrdinalIndicator } from "@/utils/common";

const AnonymousUser = Object.freeze({
  uid: "",
  displayName: "",
  email: "",
  isAnonymous: true,
  phoneNumber: "",
  photoURL: ""
});

const mutations: MutationTree<UserState> = {
  SET_USER(state, payload: User) {
    state.user = payload;
    state.userLoaded = true;
    // identify user in Sentry
    if (state.user.uid) {
      Sentry.setUser({
        id: state.user.uid,
        email: state.user.email,
        ip_address: "{{auto}}"
      });
      analytics.setUserId(state.user.uid);

      const identifiers = {
        email: state.user.email,
        user_id: state.user.uid,
        created_at: moment(state.user.metadata.creationTime).unix()
      };
      bootIntercom(identifiers);
    } else {
      //@ts-ignore
      Sentry.configureScope(scope => scope.setUser(null));
    }
  },
  SET_SOCIAL_ACCOUNT(state, payload: UserAccount) {
    const { name, accessToken, userId } = payload;

    const nextAccount = {
      name,
      userId,
      accessToken
    };

    state.connectedAccounts.accounts[name] = {
      ...state.connectedAccounts.accounts[name],
      ...nextAccount
    };
  },
  SET_STRIPE_ROLE(state, payload: string) {
    state.stripeRole = payload;
  }
};

const actions: ActionTree<UserState, RootState> = {
  init(context) {
    auth.onAuthStateChanged(async user => {
      if (!user) {
        context.commit("SET_USER", Object.freeze({ ...AnonymousUser }));
        return;
      }
      // Email verification
      if (requiresEmailVerification(user)) {
        if (router.currentRoute.name !== "Auth") {
          context.dispatch(
            "notifications/notification",
            {
              ...notificationStatus.WARNING,
              message: "You need to verify your email address!"
            },
            {
              root: true
            }
          );
        }

        context.commit("SET_USER", Object.freeze({ ...AnonymousUser }));
      } else {
        await auth.currentUser?.getIdToken(true);
        const decodedToken = await auth.currentUser?.getIdTokenResult();
        const isStaff = decodedToken?.claims?.userRole === "staff";
        const userProperties = {
          displayName: user.displayName || "",
          user_id: user.uid,
          email: user.email || ""
        };

        // Check if user has active subscription
        db.collection("customers")
          .doc(user.uid)
          .collection("subscriptions")
          .where("status", "in", ["trialing", "active"])
          .onSnapshot(
            async (snapshot: firebase.firestore.QuerySnapshot) => {
              await context.dispatch("setCustomClaimRole");

              if (snapshot.empty && !isStaff) {
                context.dispatch("upgradeCustomer");
              } else {
                await context.dispatch("boards/bindBoards", null, {
                  root: true
                });
                await context.dispatch("boards/bindViewerBoards", null, {
                  root: true
                });
              }

              for (const doc of snapshot.docs) {
                if (doc.exists) {
                  const subscription = doc.data();
                  if (subscription.trial_start) {
                    const trialStart = moment(
                      subscription.trial_start.toDate()
                    );
                    const now = moment(new Date());
                    const trialDayNumber = now.diff(trialStart, "days") + 1;
                    if (trialDayNumber > 0 && trialDayNumber <= 14) {
                      const eventName = `${trialDayNumber}${getOrdinalIndicator(
                        trialDayNumber
                      )}_day_visit`;

                      analyticsLogEvent(eventName, userProperties);
                    }
                  }

                  const userAdditionalData = await db
                    .collection("users")
                    .doc(user.uid)
                    .get();

                  if (!fbqPurchaseEventAlreadySent(userAdditionalData)) {
                    const price = await doc.data().price.get();
                    const priceUA = price.data().unit_amount;
                    const priceCurrency = price.data().currency;
                    const finalPrice = Number((priceUA / 100).toFixed(2));

                    const props = {
                      value: finalPrice,
                      currency: priceCurrency,
                      userId: user.uid,
                      userEmail: user.email
                    };
                    // @ts-ignore
                    trackFbqEvent("Purchase", props);
                    analyticsLogEvent(
                      gaEventNames.subsciption_activated_paid,
                      userProperties
                    );
                    await db
                      .collection("users")
                      .doc(user.uid)
                      .set({
                        fbqPurchaseEventSent: true
                      });
                  }
                }
              }

              if (!snapshot.empty) {
                analyticsLogEvent(
                  gaEventNames.trigger_product_tour,
                  userProperties
                );
              }
            },
            error => Sentry.captureMessage(error.message)
          );

        identifyFS(String(user.uid), userProperties);

        context.commit("SET_USER", Object.freeze({ ...user }));
        await context.dispatch(
          "bindUserSettings",
          db.collection(`users`).doc(user.uid)
        );
      }
    });
  },
  waitForUserLoaded(context) {
    return new Promise(resolve => {
      if (context.state.userLoaded) {
        resolve(true);
      } else {
        const unwatch = this.watch(
          () => this.getters["users/userHasLoaded"],
          newValue => {
            if (newValue) {
              resolve(true);
              unwatch();
            }
          }
        );
      }
    });
  },
  async logOut(context) {
    await context.dispatch("boards/unbindBoards", null, {
      root: true
    });
    await context.dispatch("boards/unbindViewerBoards", null, {
      root: true
    });
    await context.dispatch("unbindUserSettings");
    window.localStorage.clear();
    shutdownIntercom();
    await auth.signOut();
    context.commit("SET_USER", Object.freeze({ ...AnonymousUser }));
  },
  bindUserSettings: firestoreAction(async (context, userRef) => {
    await context.dispatch("waitForUserLoaded");
    return context.bindFirestoreRef("userSettings", userRef);
  }),
  unbindUserSettings: firestoreAction(context => {
    return context.unbindFirestoreRef("userSettings");
  }),
  setSocialAccount({ commit }, payload) {
    commit("SET_SOCIAL_ACCOUNT", payload);
  },
  updateUserSettings: async (context, settings: UserSettings) => {
    return db
      .collection(`users`)
      .doc(context.state.user.uid)
      .set({ ...settings });
  },
  async setCustomClaimRole({ commit }) {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    commit("SET_STRIPE_ROLE", decodedToken?.claims.stripeRole);
  },
  upgradeCustomer: () => {
    // event
  }
};

const getters: GetterTree<UserState, RootState> = {
  fullName(state): string {
    const { user } = state;
    return user.displayName;
  },
  email(state): string {
    const { user } = state;
    return user.email;
  },
  uid(state): string {
    const { user } = state;
    return user.uid;
  },
  userRefPath(state): string {
    const { user } = state;
    return `/users/${user.uid}`;
  },
  getAccountById: state => (accountId: string) => {
    const { accounts } = state.connectedAccounts;
    return accountId in accounts ? accounts[accountId] : {};
  },
  isAuthenticated(state): boolean {
    const { user } = state;
    return !user.isAnonymous;
  },
  userHasLoaded(state): boolean {
    return state.userLoaded;
  },
  avatarUrl(state): string {
    const { user } = state;
    return user.photoURL;
  },
  instagramAccount(state): UserAccount {
    const { connectedAccounts } = state;
    return connectedAccounts.accounts?.instagram;
  }
};

const users: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    user: {
      ...AnonymousUser
    },
    connectedAccounts: {
      accounts: {}
    },
    userLoaded: false,
    userSettings: {
      imageFilters: [],
      fbqPurchaseEventSent: false
    },
    stripeRole: ""
  },
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default users;
