import { BlockInitialAssignments } from "@/options/blockOptions";

// snake case more human readable
export const user_created = "user_created";
export const page_view = "page_view";
export const board_created = "board_created";
export const board_deleted = "board_deleted";
export const board_images_added = "board_images_added";
export const board_image_removed = "board_image_removed";
export const board_encrypted = "board_encrypted";
export const board_shared = "board_shared";
export const board_share_cancel = "board_share_cancel";
export const board_confirm_before_open_change =
  "board_confirm_before_open_change";
export const board_background_color_added = "board_background_color_added";
export const board_background_image_added = "board_background_image_added";
export const board_block_updated = "board_block_updated";
export const board_day_saved = "board_day_saved";
export const subsciption_activated_paid = "subsciption_activated_paid";
export const daily_routine_new_day_started = "daily_routine_new_day_started";
export const today_priorities_new_day_started =
  "today_priorities_new_day_started";
export const daily_routine_done = "daily_routine_done";
export const today_priorities_done = "today_priorities_done";
export const relationship_vision_updated = "relationship_vision_updated";
export const mission_statement_updated = "mission_statement_updated";
export const values_updated = "values_updated";
export const year_goals_updated = "year_goals_updated";
export const today_priorities_updated = "today_priorities_updated";
export const daily_routine_updated = "daily_routine_updated";
export const first_image_updated = "1st_image_updated";
export const images_field_3_added = "images_field_3_added";
export const images_field_4_added = "images_field_4_added";
export const image_block_updated = "image_block_updated";
export const trigger_product_tour = "trigger_product_tour";
export const email_verified = "email_verified";
export const board_settings_changed = "board_settings_changed";

export interface AnalyticsEventProperties {
  /* user */
  user_id?: string;
  email?: string;
  email_verified?: boolean;
  created_at?: number;

  /* for page view: */
  page_title?: string;
  page_location?: string;
  page_path?: string;

  /* For board: */
  board_id?: string;
  board_user_id?: string;

  /* for sharing: */
  board_share_type?: "email" | "link";

  /* for board options */
  board_settings?: any;

  /* image uploads: */
  board_images_count?: number;
  board_images_upload_source?: "instagram_account" | "unsplash" | "local_drive";

  /* board settings update: */
  board_confirm_before_open?: boolean;
  board_background_color?: string;

  /* editor changed (which): */
  board_editor_type?: BlockInitialAssignments;
}

export type AnalyticsEventName = string;
