























































































































































































































import mixins from "vue-typed-mixins";
import { mapState, mapGetters } from "vuex";
import Boards from "@/components/Boards/Boards.vue";
import BoardSettings from "@/components/Boards/BoardSettings.vue";
import Profile from "@/components/Users/Profile.vue";
import userMixin from "@/mixins/user";
import { db } from "@/plugins/firebase";
import { mdiChevronDown, mdiLogin, mdiClose, mdiMenu, mdiPlus } from "@mdi/js";
import { BoardConfig } from "@/options/boardOptions";
import SaveIndicator from "./Boards/SaveIndicator.vue";

export default mixins(userMixin).extend({
  name: "Toolbar",
  components: { Boards, BoardSettings, Profile, SaveIndicator },
  mixins: [userMixin],
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editToolbarTitle: false,
    helpUrl: process.env.VUE_APP_VB_HELP,
    communityUrl: process.env.VUE_APP_VB_COMMUNITY,
    newBoardName: "",
    maxTitleWidth: 228,
    toolbarTextWidth: 0,
    svgChevronDown: mdiChevronDown,
    svgLogin: mdiLogin,
    svgClose: mdiClose,
    svgMenu: mdiMenu,
    svgPlus: mdiPlus
  }),
  computed: {
    ...mapState("boards", ["currentBoard", "boards", "viewerBoards"]),
    ...mapGetters("users", ["isAuthenticated", "avatarUrl", "fullName"]),
    canEditBoard() {
      return this.$route.name === "Boards" && this.currentBoard && this.owner;
    },
    boardsExist() {
      return this.boards?.length > 0 || this.viewerBoards?.length > 0;
    }
  },
  watch: {
    "currentBoard.id"(val) {
      if (val) {
        this.isOwner(val);
      }
    },
    "currentBoard.name"(val) {
      if (val) {
        this.newBoardName = val;
      }
    }
  },
  mounted() {
    this.isOwner(this.currentBoard?.id);
  },
  methods: {
    editBoardName() {
      const el = (this.$refs.menu as Vue).$el;
      this.toolbarTextWidth = Math.round(el.getBoundingClientRect().width);
      this.editToolbarTitle = true;
    },
    onBoardChange(board: BoardConfig) {
      const nextPath = `/boards/${board.id}`;
      if (this.$route.path !== nextPath) this.$router.push(nextPath);
    },
    async renameBoard(name: string) {
      await db
        .collection(`boards`)
        .doc(this.currentBoard.id)
        .update({ name: name ? name : "Unnamed" });
      this.editToolbarTitle = false;
    },
    login() {
      if (this.$route.path !== "/auth") this.$router.push({ name: "Auth" });
    }
  }
});
