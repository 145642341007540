<template>
  <div>
    <v-radio
      color="primary"
      class="radio"
      :off-icon="svgRadioboxBlank"
      :on-icon="svgRadioboxMarked"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:label>
        <label class="text-body-2">
          <slot></slot>
        </label>
      </template>
    </v-radio>
  </div>
</template>
<script>
import { mdiRadioboxBlank, mdiRadioboxMarked } from "@mdi/js";

export default {
  name: "BaseRadio",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    svgRadioboxBlank: mdiRadioboxBlank,
    svgRadioboxMarked: mdiRadioboxMarked
  }),
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: v => {
          this.$emit("input", v);
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.radio {
  cursor: pointer;
}

::v-deep .v-icon__svg {
  fill: $background;
}

::v-deep .v-item--active svg {
  fill: $primary;
}
</style>
