


















































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { billingPortal } from "@/helpers/paymentHelpers";
import { mdiCashLockOpen } from "@mdi/js";

export default Vue.extend({
  name: "Profile",
  components: {
    SettingsForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Account/SettingsForm.vue"
      )
  },
  data() {
    return {
      settingModal: false,
      subscriptionsUrl: process.env.VUE_APP_VB_SUBSCRIPTIONS,
      svgCashLockOpen: mdiCashLockOpen
    };
  },
  computed: {
    ...mapState("users", ["stripeRole"]),
    ...mapGetters("users", ["fullName", "email"])
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("users/logOut");
      if (this.$route.path !== "/auth") this.$router.push({ name: "Auth" });
    },
    async accessPortal() {
      await billingPortal();
    }
  }
});
