<template>
  <v-btn
    :color="color"
    :small="!$attrs.hasOwnProperty('outlined')"
    icon
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: "BaseButtonIcon",
  props: {
    color: {
      type: String,
      default: "background"
    }
  }
};
</script>

<style scoped>
.v-btn--outlined {
  border: 2px solid;
}
</style>
