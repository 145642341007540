import * as FullStory from "@fullstory/browser";

if (process.env.VUE_APP_FULLSTORY_APP_ID)
  FullStory.init({ orgId: process.env.VUE_APP_FULLSTORY_APP_ID });

export const identifyFS = (userId: string, properties: any) => {
  if (process.env.NODE_ENV === "production") {
    FullStory.identify(String(userId), {
      ...properties
    });
  }
};
