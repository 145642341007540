































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import Boards from "@/components/Boards/Boards.vue";
import { mdiChevronDown } from "@mdi/js";
import { BoardConfig } from "@/options/boardOptions";

export default Vue.extend({
  name: "Menu",
  components: { Boards },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    helpUrl: process.env.VUE_APP_VB_HELP,
    communityUrl: process.env.VUE_APP_VB_COMMUNITY,
    group: null,
    svgChevronDown: mdiChevronDown
  }),
  computed: {
    ...mapState("boards", ["currentBoard", "boards", "viewerBoards"]),
    ...mapGetters("users", [
      "fullName",
      "email",
      "isAuthenticated",
      "avatarUrl"
    ]),
    boardsExist() {
      return this.boards?.length > 0 || this.viewerBoards?.length > 0;
    }
  },
  methods: {
    onBoardChange(board: BoardConfig) {
      const nextPath = `/boards/${board.id}`;
      if (this.$route.path !== nextPath) this.$router.push(nextPath);
    },
    login() {
      if (this.$route.path !== "/auth") this.$router.push({ name: "Auth" });
    }
  }
});
