import { analytics } from "@/plugins/firebase";
import {
  AnalyticsEventProperties,
  AnalyticsEventName
} from "@/options/analyticsOptions";
import { BlockInitialAssignments } from "@/options/blockOptions";
import { BoardConfig } from "@/options/boardOptions";
import Sentry from "@/plugins/sentry";

export const firestoreLogAnalytics = (
  eventName: AnalyticsEventName,
  eventProperties: AnalyticsEventProperties
) => {
  const data = {
    eventName,
    eventProperties
  };

  const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/setAnalytics`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      event: {
        ...data
      }
    })
  })
    .then(res => res.json())
    .catch(e => Sentry.captureMessage(e));
};

const intercomLogAnalytics = (
  eventName: AnalyticsEventName,
  eventProperties: AnalyticsEventProperties
) => {
  window.Intercom("trackEvent", eventName, eventProperties);
};

const logAnalytics = (
  eventName: AnalyticsEventName,
  eventProperties: AnalyticsEventProperties
) => {
  // @ts-ignore
  analytics.logEvent(eventName, eventProperties);
  firestoreLogAnalytics(eventName, eventProperties);
  intercomLogAnalytics(eventName, eventProperties);
};

export const analyticsLogEvent = (
  eventName: AnalyticsEventName,
  eventProperties: AnalyticsEventProperties
) => {
  if (process.env.NODE_ENV === "production") {
    logAnalytics(eventName, eventProperties);
  } else {
    console.log(
      "Skipping analytics logger in development mode: ",
      `"${eventName}"`,
      eventProperties
    );
  }
};

export const analyticsLogEventGenerator = (
  assignment: BlockInitialAssignments,
  currentBoard: BoardConfig,
  suffix: string
) => {
  analyticsLogEvent(`${assignment}_${suffix}` as AnalyticsEventName, {
    board_id: currentBoard.id,
    board_user_id: currentBoard.user,
    board_editor_type: assignment
  });
};
