















import { mapState } from "vuex";
import { mdiSync, mdiCheck } from "@mdi/js";
import Vue from "vue";

export default Vue.extend({
  name: "SaveIndicator",
  data: () => ({
    svgSync: mdiSync,
    svgCheck: mdiCheck
  }),
  computed: {
    ...mapState("boards", ["currentBoardSaving"])
  }
});
