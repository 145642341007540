import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import boards from "@/store/modules/boards";
import users from "@/store/modules/users";
import notifications from "@/store/modules/notifications";
import modals from "@/store/modules/modals";
import { vuexfireMutations } from "vuexfire";
import { RootState } from "@/store/types";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: process.env.VUE_APP_VERSION || "0"
  },
  mutations: {
    ...vuexfireMutations
  },
  actions: {},
  modules: { boards, users, notifications, modals },
  strict: process.env.NODE_ENV !== "production"
};

export default new Vuex.Store<RootState>(store);
