<template>
  <v-dialog
    :value="value"
    v-bind="attrs"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="!attrs.persistent && onCancel()"
    @keydown.esc="!attrs.persistent && onCancel()"
  >
    <v-card class="pa-5">
      <slot name="title">
        <v-card-title v-if="title" class="text-h5 text-left font-weight-bold">
          {{ title }}
        </v-card-title>
        <BaseButtonIcon
          v-if="close"
          data-testid="close-modal-button"
          absolute
          right
          top
          color="gray"
          style="z-index: 1;"
          @click="onCancel()"
        >
          <v-icon>{{ svgClose }}</v-icon>
        </BaseButtonIcon>
      </slot>

      <v-card-text>
        <slot v-if="loading" name="loading">
          <Progress />
        </slot>
        <slot v-else></slot>
      </v-card-text>

      <slot v-if="!hideActions" name="actions">
        <v-card-actions>
          <v-spacer></v-spacer>
          <BaseButton
            outlined
            color="gray"
            :disabled="loading"
            @click="onCancel"
          >
            {{ cancelText || "Cancel" }}
          </BaseButton>
          <BaseButton
            v-if="$listeners['confirm']"
            :disabled="loading"
            @click="$emit('confirm')"
          >
            {{ confirmText || "Save" }}
          </BaseButton>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "BaseModal",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    cancelText: String,
    confirmText: String,
    loading: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: true
    },
    hideActions: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    svgClose: mdiClose
  }),
  computed: {
    attrs() {
      return { "max-width": "900", ...this.$attrs };
    }
  },
  methods: {
    onCancel() {
      this.$emit("update:value", false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog--fullscreen {
  > .v-card {
    padding: 24px 12px 0 12px !important;
  }
}
</style>
