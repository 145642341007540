<template>
  <BaseFormGroup
    :validator="validations[name]"
    :label="label"
    :validator-params="validatorParams"
  >
    <template v-slot="{ errors }">
      <v-text-field
        :value="value"
        :counter="counterValue"
        :color="color"
        :class="inputClass"
        :style="inputStyle"
        v-bind="{ ...attrs, ...errors }"
        v-on="listeners"
      >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      </v-text-field>
    </template>
  </BaseFormGroup>
</template>
<script>
export default {
  name: "BaseInput",
  props: {
    name: String,
    value: {
      required: true
    },
    inputClass: {
      type: [String]
    },
    inputStyle: {
      type: [Object]
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    validatorParams: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    attrs() {
      Object.keys(this.$attrs).forEach(
        key => this.$attrs[key] === undefined && delete this.$attrs[key]
      );
      return { hideDetails: "auto", ...this.$attrs };
    },
    label() {
      return this.attrs.label || this.attrs.placeholder;
    },
    color() {
      return this.attrs.color || "primary";
    },
    listeners() {
      return {
        ...this.$listeners,
        input: v => {
          if (this.name) {
            const validator = this.validations[this.name];
            if (validator) {
              validator.$touch();
            }
          }
          this.$emit("input", v);
        }
      };
    },
    counterValue() {
      if ((this.validations[this.name] || {}).maxLength) {
        return this.validations[this.name].$params.maxLength.max;
      }
      return false;
    }
  }
};
</script>
