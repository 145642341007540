<template>
  <div
    class="wrapper"
    :style="{
      backgroundImage: `url(${backgroundImg})`
    }"
  >
    <v-container>
      <Progress v-if="loading" />
      <v-stepper
        v-else
        v-model="currentStep"
        flat
        vertical
        :rounded="false"
        :tile="false"
        :translate="true"
      >
        <div class="stepper-wrapper">
          <v-stepper-items>
            <v-stepper-content
              :step="1"
              v-bind:class="{ active: currentStep === 1 }"
            >
              <div class="mb-4 step-content">
                <div class="d-flex flex-row align-center justify-center">
                  <div class="text-h6">{{ currentStep }}</div>
                  <Icon small color="white">{{ mdiArrowRight }}</Icon>
                  <div class="text-h3 ml-2 font-weight-bold">
                    What's your name? *
                  </div>
                </div>
                <BaseInput
                  ref="name"
                  v-model.trim="formData.name"
                  :validations="$v.formData"
                  name="name"
                  placeholder="Type your answer here..."
                  large
                  color="white"
                  full-width
                />
                <div class="mt-10">
                  <BaseButton large :color="`white`" @click="nextStep">
                    OK
                    <Icon color="black">{{ mdiCheck }}</Icon>
                  </BaseButton>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content
              :step="2"
              v-bind:class="{ active: currentStep === 2 }"
            >
              <div class="mb-4 step-content">
                <div class="d-flex flex-row align-center justify-center">
                  <div class="text-h6">{{ currentStep }}</div>
                  <Icon small color="white">{{ mdiArrowRight }}</Icon>
                  <div class="text-h3 ml-2 font-weight-bold">
                    And what's your email address?
                  </div>
                </div>
                <div class="additional-text">
                  We need it to send you a feedback form
                </div>
                <BaseInput
                  ref="email"
                  v-model.trim="formData.email"
                  :validations="$v.formData"
                  name="email"
                  placeholder="Type your answer here..."
                  large
                  color="white"
                  full-width
                />
                <div class="mt-10">
                  <BaseButton large :color="`white`" @click="nextStep">
                    OK
                    <Icon color="black">{{ mdiCheck }}</Icon>
                  </BaseButton>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content
              :step="3"
              v-bind:class="{ active: currentStep === 3 }"
            >
              <div class="mb-4 step-content">
                <div class="d-flex flex-row align-center justify-center">
                  <div class="text-h6">{{ currentStep }}</div>
                  <Icon small color="white">{{ mdiArrowRight }}</Icon>
                  <div class="text-h3 ml-2 font-weight-bold">
                    Create your password *
                  </div>
                </div>
                <BaseInput
                  ref="password"
                  v-model.trim="formData.password"
                  :validations="$v.formData"
                  name="password"
                  type="password"
                  placeholder="Type your answer here..."
                  large
                  color="white"
                  full-width
                />
                <div class="mt-10">
                  <BaseButton large :color="`white`" @click="nextStep">
                    OK
                    <Icon color="black">{{ mdiCheck }}</Icon>
                  </BaseButton>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </div>
        <div v-if="error" class="text-center error--text">
          {{ error }}
        </div>
        <v-btn-toggle class="bottom-nav">
          <BaseButton
            v-if="hasPrevStep"
            background="white"
            color="white"
            @click="prevStep"
          >
            <Icon>{{ mdiArrowUp }}</Icon>
          </BaseButton>
          <BaseButton v-if="hasNextStep" color="white" @click="nextStep">
            <Icon>{{ mdiArrowDown }}</Icon>
          </BaseButton>
        </v-btn-toggle>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import backgroundImg from "@/assets/ea_bg.png";
import { mdiCheck, mdiArrowRight, mdiArrowDown, mdiArrowUp } from "@mdi/js";
import { email, minLength, required } from "vuelidate/lib/validators";
import { auth } from "@/plugins/firebase";
import store from "@/store";
import moment from "moment";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";

const steps = {
  1: "name",
  2: "email",
  3: "password"
};

export default {
  name: "EarlyAccess",
  data: () => ({
    backgroundImg,
    formData: {
      name: "",
      email: "",
      password: ""
    },
    error: "",
    steps,
    loading: false,
    currentStep: 1,
    transition: "slide-next",
    mdiCheck,
    mdiArrowRight,
    mdiArrowDown,
    mdiArrowUp
  }),
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("users/waitForUserLoaded");
    if (store.getters["users/isAuthenticated"]) {
      if (to.query.redirectPath) {
        next(to.query.redirectPath.toString());
      } else {
        next({ name: "Home" });
      }
    } else {
      next();
    }
  },
  computed: {
    hasNextStep() {
      return this.currentStep < Object.keys(this.steps).length;
    },
    hasPrevStep() {
      return this.currentStep > 1;
    }
  },
  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val;
      }
    }
  },
  methods: {
    prevStep() {
      if (this.currentStep === 1) {
        return;
      }
      this.currentStep -= 1;
    },
    nextStep() {
      if (this.error) this.error = "";
      const currentStepName = this.steps[this.currentStep];
      this.$v.formData[currentStepName].$touch();
      if (this.$v.formData[currentStepName].$invalid) {
        this.$refs[currentStepName].$el.querySelector("input").focus();
        return;
      }

      if (this.currentStep === Object.keys(this.steps).length) {
        this.submitUser();
      } else {
        this.currentStep = this.currentStep + 1;
      }
    },
    submitUser() {
      this.loading = true;
      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/createNewUser`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: this.formData.email,
          password: this.formData.password,
          displayName: this.formData.name
        })
      })
        .then(async res => {
          if (!res.ok) {
            const err = new Error("HTTP status code: " + res.status);
            err.response = res;
            err.response.data = await res.json();
            err.status = res.status;
            throw err;
          }

          return res.json();
        })
        .then(res => {
          if (res.success && res.user) {
            const props = {
              email: res.user.email,
              user_id: res.user.uid,
              created_at: moment(res.user.metadata.creationTime).unix()
            };

            analyticsLogEvent("user_created", props);
            auth
              .signInWithEmailAndPassword(
                this.formData.email,
                this.formData.password
              )
              .then(() => {
                const path = "/payment?interval=month&interval_count=6&tp=ea";
                window.location.assign(path);
              });
          }
        })
        .catch(err => {
          if (err.response.data) {
            const { code, message } = err.response.data;
            switch (code) {
              case "auth/email-already-exists":
                this.currentStep = 2;
                break;
              case "auth/invalid-password":
                this.currentStep = 3;
                break;
            }

            this.error = message;
            this.loading = false;
          }
        });
    }
  },
  validations() {
    return {
      formData: {
        name: { required, minLength: minLength(4) },
        password: { required, minLength: minLength(6) },
        email: { required, email },
        goal: {}
      }
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-stepper {
  background: transparent !important;
  box-shadow: none;
}
.wrapper {
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: $background;
  min-height: 100vh;
  color: white;
}

.additional-text {
  font-size: 1rem;
  color: #9092c2;
  margin: 10px 0;
}

.step-content .text-h3 {
  text-align: left;
  font-size: 1.2rem !important;
  line-height: 1.625rem !important;
}
.step-content .text-h6 {
  font-size: 1.2rem !important;
  line-height: 1.625rem !important;
}

.stepper-wrapper {
  min-height: 220px;
  margin-top: 20px;
}
@media (min-width: 960px) {
  .step-content .text-h3 {
    text-align: center;
    font-size: 2.225rem !important;
  }
  .stepper-wrapper {
    height: auto;
    min-height: 350px;
    margin-top: 120px;
  }
  .additional-text {
    font-size: 1.225rem;
    color: #54576f;
    margin: 40px 0;
  }
  ::v-deep .v-text-field > .v-input__control > .v-input__slot input {
    font-size: 2rem !important;
  }
  ::v-deep
    .v-text-field
    > .v-input__control
    > .v-input__slot
    input::placeholder {
    color: #54576f;
  }
}
.step-content {
  text-align: center;
}

.bottom-nav {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
}

::v-deep .v-stepper__wrapper {
  transition-duration: 0.2s !important;
}

::v-deep .v-stepper__content {
  position: absolute;
  margin-left: 0 !important;
}

::v-deep .v-stepper__content.active {
  position: static;
  margin-left: 0 !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #54576f !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot input {
  color: #fff;
  font-size: 1rem;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot input::placeholder {
  color: #9092c2;
}
</style>
