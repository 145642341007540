import { firestoreAction } from "vuexfire";
import { db, storage } from "@/plugins/firebase";
import firebase from "firebase/app";
import { Board, BoardState, RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import Sentry from "@/plugins/sentry";
import { FirebaseError } from "firebase-admin";
import { BoardConfig } from "@/options/boardOptions";

const mutations: MutationTree<BoardState> = {
  SET_CREATE_IN_PROGRESS(state, inProgress: boolean) {
    state.createInProgress = inProgress;
  },
  SET_UPDATE_IN_PROGRESS(state, inProgress: boolean) {
    state.updateInProgress = inProgress;
  },
  SET_BOARD_CONFIG(state, payload) {
    state.currentBoardConfig = JSON.parse(JSON.stringify(payload));
  },
  SET_CURRENT_BOARD_SAVING(state, currentBoardSaving: boolean) {
    state.currentBoardSaving = currentBoardSaving;
  }
};

const actions: ActionTree<BoardState, RootState> = {
  bindBoards: firestoreAction(async context => {
    await context.dispatch("users/waitForUserLoaded", null, { root: true });
    const userBoards = db
      .collection("boards")
      .where("user", "==", context.rootGetters["users/uid"])
      .orderBy("createdAt", "desc");

    return context.bindFirestoreRef("boards", userBoards);
  }),
  bindViewerBoards: firestoreAction(async context => {
    await context.dispatch("users/waitForUserLoaded", null, { root: true });
    const viewerBoards = db
      .collection("boards")
      .where("viewers", "array-contains", context.rootGetters["users/email"])
      .orderBy("createdAt", "desc");

    return context.bindFirestoreRef("viewerBoards", viewerBoards);
  }),
  unbindBoards: firestoreAction(context => {
    return context.unbindFirestoreRef("boards");
  }),
  unbindViewerBoards: firestoreAction(context => {
    return context.unbindFirestoreRef("viewerBoards");
  }),
  bindCurrentBoard: firestoreAction(async (context, boardRef) => {
    await context.dispatch("users/waitForUserLoaded", null, { root: true });
    return context.bindFirestoreRef("currentBoard", boardRef);
  }),
  unbindCurrentBoard: firestoreAction(context => {
    return context.unbindFirestoreRef("currentBoard");
  }),
  bindBoardConfig: (context, boardConfig: BoardConfig) => {
    context.commit("SET_BOARD_CONFIG", boardConfig);
  },
  addBoard: async (context, board: Board) => {
    const user = context.rootGetters["users/uid"];
    board.user = user;
    board.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    board.private = true;
    board.change = "";
    board.viewers = [];
    board.encrypted = false;
    board.confirmBeforeOpen = false;
    return db
      .collection(`boards`)
      .add({ ...board })
      .then(newBoard => {
        analyticsLogEvent(gaEventNames.board_created, {
          board_id: newBoard.id,
          board_user_id: user
        });
        return newBoard;
      });
  },
  deleteBoard: async (context, boardId: string) => {
    context.getters
      .getBoardStorageRef(boardId)
      .delete()
      .catch((e: FirebaseError) => {
        if (e.code !== "storage/object-not-found") throw e;
        Sentry.captureException(e);
      });

    return db
      .collection(`boards`)
      .doc(boardId)
      .delete();
  },
  encryptBoard: () => {
    // event
  },
  saveBoard: () => {
    // event
  },
  openBoardForm: () => {
    // event
  },
  switchCreateInProgress({ commit }, inProgress: boolean) {
    commit("SET_CREATE_IN_PROGRESS", inProgress);
  },
  switchUpdateInProgress({ commit }, inProgress: boolean) {
    commit("SET_UPDATE_IN_PROGRESS", inProgress);
  },
  switchCurrentBoardSaving({ commit }, currentBoardSaving: boolean) {
    commit("SET_CURRENT_BOARD_SAVING", currentBoardSaving);
  }
};

const getter: GetterTree<BoardState, RootState> = {
  getBoardStorageRef: () => {
    return (boardId: string) => storage.ref(`boards/${boardId}/data.json`);
  },
  /* anyone has a better name? could be -  communityStorageRef */
  unencryptedBoards(state): Board[] {
    const { boards } = state;
    return boards?.filter(x => !x?.encrypted);
  },
  unencryptedBoardExist(state, getters): boolean {
    const { unencryptedBoards } = getters;
    return unencryptedBoards.length > 0;
  }
};

const boards: Module<BoardState, RootState> = {
  namespaced: true,
  state: {
    boards: [],
    viewerBoards: [],
    currentBoard: undefined,
    currentBoardConfig: undefined,
    createInProgress: false,
    updateInProgress: false,
    currentBoardSaving: false
  },
  mutations: mutations,
  actions: actions,
  getters: getter
};

export default boards;
