import Vue from "vue";
import VueMeta from "vue-meta";
import "./plugins/vuelidate";
import "./plugins/sentry";
import App from "./App.vue";
import wb from "./registerServiceWorker";
import router from "./router";
import "./router/guards";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Icon from "./components/Shared/Icon.vue";
import Questionmark from "./components/Shared/Questionmark.vue";
import Progress from "./components/Shared/Progress.vue";
import i18n from "./i18n";
import { Workbox } from "workbox-window";

Vue.prototype.$workbox = wb;

const requireComponent = require.context(
  "@/components/Base",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
  Vue.component(componentName, componentConfig.default || componentConfig);
});
Vue.component("Icon", Icon);
Vue.component("Questionmark", Questionmark);
Vue.component("Progress", Progress);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    this.$store.dispatch("users/init");
  },
  render: h => h(App)
}).$mount("#app");

/* istanbul ignore else */
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.app = app;
}

declare module "vue/types/vue" {
  interface Vue {
    $workbox: Workbox;
  }
}
