export const formatPriceUnitAmount = (unitAmount: number, currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  }).format(Number((unitAmount / 100).toFixed(2)));
};
export const getOrdinalIndicator = (num: number) => {
  const array = ("" + num).split("").reverse();

  if (array[1] != "1") {
    switch (array[0]) {
      case "1":
        return "st";
      case "2":
        return "nd";
      case "3":
        return "rd";
    }
  }

  return "th";
};
