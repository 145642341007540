import { RootState, Notification, NotificationState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const getters: GetterTree<NotificationState, RootState> = {};

const mutations: MutationTree<NotificationState> = {
  SET_NOTIFICATION(state, notification: Notification) {
    if (!state.notifications.some(n => n?.message === notification.message)) {
      state.notifications.push(notification);
    }
  },
  RESET_NOTIFICATIONS(state) {
    state.notifications = [];
  }
};

const actions: ActionTree<NotificationState, RootState> = {
  notification({ commit }, notification: Notification) {
    commit("SET_NOTIFICATION", notification);
  }
};

const notifications: Module<NotificationState, RootState> = {
  namespaced: true,
  state: {
    notifications: []
  },
  getters,
  mutations,
  actions
};

export default notifications;
