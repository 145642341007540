<template>
  <v-row>
    <v-col class="text-center">
      <v-progress-circular
        size="56"
        :color="color"
        indeterminate
        width="6"
        v-bind="$attrs"
        v-on="$listeners"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Progress",
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>
