






























































import Vue from "vue";
import Notification from "@/components/Shared/Notification.vue";
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import Toolbar from "@/components/Toolbar.vue";
import { MetaInfo } from "vue-meta/types";
import { mdiClose } from "@mdi/js";
import { loadIntercom, toggleIntercomButton } from "@/plugins/intercom";
import { ActionPayload, mapGetters } from "vuex";

if (!window.Cypress) {
  loadIntercom();
}

export default Vue.extend({
  name: "App",
  metaInfo(): MetaInfo {
    return {
      title: "Visionbook",
      meta: [
        {
          name: "description",
          content: "Create and share your Vision!"
        },
        {
          property: "og:title",
          content: "Visionbook"
        },
        { property: "og:site_name", content: "Visionbook" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
        {
          property: "og:image",
          content: `https://app.visionbook.com/img/preview.png`
        }
      ]
    };
  },
  components: {
    UpgradeModal: () =>
      import(/* webpackPrefetch: true */ "@/components/Users/UpgradeModal.vue"),
    BoardForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardForm.vue"
      ),
    ShareForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardShareForm.vue"
      ),
    Notification,
    Menu,
    Footer,
    Toolbar
  },
  data: () => ({
    boardModal: false,
    shareModal: false,
    upgradeModal: false,
    serviceWorkerWaiting: false,
    drawer: false,
    unsubscribe: null as any,
    creationModePicked: false,
    svgClose: mdiClose
  }),
  computed: {
    ...mapGetters("boards", ["unencryptedBoardExist"])
  },
  watch: {
    async upgradeModal(val) {
      if (!val) {
        await this.$store.dispatch("users/logOut");
        if (this.$route.path !== "/auth") this.$router.push({ name: "Auth" });
      }
    },
    boardModal(val) {
      toggleIntercomButton(val);
    },
    shareModal(val) {
      toggleIntercomButton(val);
      if (!val) {
        this.creationModePicked = false;
      }
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", async () => {
        this.serviceWorkerWaiting = true;
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        this.serviceWorkerWaiting = false;
      });
    }
    this.unsubscribe = this.$store.subscribeAction(
      async (action: ActionPayload): Promise<void> => {
        const path = action.type.split("/");
        if (
          path[0] === "users" &&
          path[1] === "upgradeCustomer" &&
          this.$route.name !== "Payment"
        ) {
          this.upgradeModal = true;
        }

        if (path[0] === "boards" && path[1] === "openBoardForm") {
          this.boardModal = true;
        }
      }
    );
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    openBoardModal() {
      this.drawer = false;
      this.boardModal = true;
    },
    openShareModal() {
      this.drawer = false;
      this.shareModal = true;
    }
  }
});
