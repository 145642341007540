import { RootState, ModalState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const getters: GetterTree<ModalState, RootState> = {
  modal: state => (modalName: string) => {
    const modalsState = state.modals;
    return modalName in modalsState ? modalsState[modalName] : {};
  },
  instagramModal(state): boolean {
    const modalsState = state.modals;
    return modalsState.instagram;
  }
};

const mutations: MutationTree<ModalState> = {
  TOGGLE_MODAL(state, modalName: string) {
    state.modals[modalName] = !state.modals[modalName];
  }
};

const actions: ActionTree<ModalState, RootState> = {
  toggleModal({ commit }, modalName: string) {
    commit("TOGGLE_MODAL", modalName);
  },

  openShareModal: () => {
    // event
  }
};

const modals: Module<ModalState, RootState> = {
  namespaced: true,
  state: {
    modals: {
      instagram: false
    }
  },
  getters,
  mutations,
  actions
};

export default modals;
