import firebase from "firebase/app";
import "firebase/performance";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import Vue from "vue";
import Sentry from "@/plugins/sentry";

const firebaseConfig = {
  // Add your config here
  apiKey: process.env.VUE_APP_FIREBASE_apiKey,
  authDomain: process.env.VUE_APP_FIREBASE_authDomain,
  databaseURL: process.env.VUE_APP_FIREBASE_databaseURL,
  projectId: process.env.VUE_APP_FIREBASE_projectId,
  storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
  appId: process.env.VUE_APP_FIREBASE_appId,
  measurementId: process.env.VUE_APP_FIREBASE_measurementId
};

firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === "production") {
  firebase.performance();
} else {
  //Testing sms verification without the need of solving captcha
  //firebase.auth().settings.appVerificationDisabledForTesting = true;
}
export const analytics = firebase.analytics();
// Alias: use as this.$analytics.logEvent("notification_received") in a component;
// see: https://developers.google.com/gtagjs/reference/ga4-events
Vue.prototype.$analytics = analytics;

export const db = firebase.firestore();
if (process.env.VUE_APP_FIRESTORE_EMULATOR_HOST) {
  db.settings({
    experimentalForceLongPolling: window.Cypress ? true : false, // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    host: process.env.VUE_APP_FIRESTORE_EMULATOR_HOST,
    ssl: false
  });
  db.clearPersistence();
}
// db.enablePersistence({ synchronizeTabs: true }).catch(err => {
//   if (err.code == "failed-precondition") {
//     Sentry.captureMessage(
//       `${err.code} - Multiple tabs open, persistence can only be enabled in one tab at a a time.`
//     );
//   } else if (err.code == "unimplemented") {
//     Sentry.captureMessage(
//       `${err.code} - The current browser does not support all of the features required to enable persistence`
//     );
//   } else if (err.code == "unavailable") {
//     Sentry.captureMessage(err.code);
//     window.location.reload();
//   } else {
//     Sentry.captureMessage(err.code);
//   }
// });
export const auth = firebase.auth();
export const storage = firebase.storage();
if (process.env.VUE_APP_FIREBASE_STORAGE_HOST && !window.Cypress) {
  storage.useEmulator("localhost", +process.env.VUE_APP_FIREBASE_STORAGE_HOST);
}
export const functions = firebase.app().functions("europe-west1");
if (process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST) {
  // functions.useFunctionsEmulator(
  //   `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}`
  // );
}
