










































import Vue from "vue";
// @ts-ignore
import colorcolor from "colorcolor";
import { mapActions, mapState } from "vuex";
import { toggleIntercomButton } from "@/plugins/intercom";
import { getCurrentThemeColors } from "@/helpers/vuetifyHelpers";
import { db } from "@/plugins/firebase";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { Board } from "@/store/types";
import { getGlobalFont } from "@/utils/font";

export default Vue.extend({
  name: "BoardSettings",
  components: {
    BoardSettingsForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardSettingsForm.vue"
      )
  },
  data() {
    return {
      settingModal: false,
      settings: null as Board | null,
      options: {
        colors: getCurrentThemeColors(),
        fontFamily: "",
        background: ""
      }
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    boardId(): string {
      return this.currentBoard?.id;
    },
    confirmBeforeOpen: {
      get(): boolean {
        return this.settings?.confirmBeforeOpen || false;
      },
      set(newVal: boolean) {
        analyticsLogEvent(gaEventNames.board_confirm_before_open_change, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_confirm_before_open: newVal
        });
        if (this.settings) this.settings.confirmBeforeOpen = newVal;
      }
    }
  },
  watch: {
    settingModal(val) {
      toggleIntercomButton(val);
    }
  },
  mounted() {
    const bg = (document.getElementsByClassName("div--board")[0] as HTMLElement)
      ?.style?.background;

    if (bg) {
      this.options.background = bg;
      if (!bg.includes("base64")) {
        this.options.background = colorcolor(bg, "hexa");
      }
    }
    this.options.fontFamily = getGlobalFont() || "";
    this.settings = { ...this.currentBoard };
  },
  methods: {
    ...mapActions("boards", ["encryptBoard", "saveBoard"]),
    onOptionsUpdate(opts: any) {
      this.options = {
        ...this.options,
        ...opts
      };
    },
    onSettingsUpdate(settings: any) {
      this.settings = {
        ...this.settings,
        ...settings
      };
    },
    async saveBoardSettings() {
      await db
        .collection("boards")
        .doc(this.boardId)
        .update({ confirmBeforeOpen: this.settings?.confirmBeforeOpen });
      await this.saveBoard({
        ...this.options
      });

      analyticsLogEvent(gaEventNames.board_settings_changed, {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user,
        board_settings: {
          ...this.options
        }
      });

      this.settingModal = false;
    },
    openSettingsModal() {
      if (this.settingModal) {
        this.settingModal = false;
        this.$nextTick(() => {
          this.settingModal = true;
        });
      } else {
        this.settingModal = true;
      }
    }
  }
});
