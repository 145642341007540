<template>
  <v-tooltip
    v-model="show"
    content-class="v-tooltip__content--custom"
    top
    :disabled="!tooltip"
    z-index="204"
  >
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template v-slot:activator="{ on }">
      <BaseButtonIcon
        :class="btnClasses"
        :style="btnStyles"
        :color="color"
        v-bind="$attrs"
        @mouseover="show = true"
        @mouseleave="leave"
      >
        <Icon small :icon-class="iconClass">vb-questionmark</Icon>
      </BaseButtonIcon>
    </template>
    <div @mouseleave="show = false" v-html="tooltip"></div>
  </v-tooltip>
</template>
<script>
export default {
  name: "Questionmark",
  props: {
    color: {
      type: String,
      required: false
    },
    tooltip: {
      type: String
    },
    styles: {
      type: [Object]
    },
    btnClass: {
      type: [String]
    },
    iconClass: {
      type: [String]
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    btnStyles() {
      return typeof this.styles === "object" ? this.styles : {};
    },
    btnClasses() {
      return this.show
        ? `v-tooltip__icon--after ${this.btnClass}`
        : this.btnClass;
    }
  },
  methods: {
    leave($event) {
      $event.preventDefault();
      setTimeout(() => {
        const target = $event.toElement || $event.relatedTarget;
        if (!target?.classList?.contains("v-tooltip__content--custom")) {
          this.show = false;
        }
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip__content--custom {
  margin-top: 5px;
  padding: 8px 24px;
  box-shadow: inset -1px -1px 0 1px $background;
}
.v-tooltip__icon--after {
  &::after {
    content: " ";
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px 5px;
    border-style: solid;
    border-color: $background transparent transparent transparent;
    opacity: 0;
    animation: 1s fadeIn;
    animation-fill-mode: forwards;
    transition: opacity 0.6s;
    z-index: 22;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
</style>
