























































import Vue from "vue";
import Sentry from "@/plugins/sentry";
import store from "@/store/index";
import firebase from "firebase/app";
import { auth } from "@/plugins/firebase";
import * as firebaseui from "firebaseui";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import { mapActions } from "vuex";
import moment from "moment";
import {
  PricingPlanDescription,
  ProductConfig
} from "@/options/paymentOptions";
import { getPaymentProduct, getUrlParams } from "@/helpers/paymentHelpers";
import { notificationStatus } from "@/options/notificationOptions";
import { formatPriceUnitAmount } from "@/utils/common";
import { hasProviderEmailVerification } from "@/helpers/userHelpers";

export default Vue.extend({
  name: "Auth",
  data: function() {
    return {
      defaultPlanInterval: "year",
      plan: undefined,
      planLoaded: false,
      selectedPrice: null as PricingPlanDescription | null,
      sending: false
    };
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("users/waitForUserLoaded");
    if (store.getters["users/isAuthenticated"]) {
      if (to.query.redirectPath) {
        next(to.query.redirectPath.toString());
      } else {
        next({ name: "Home" });
      }
    } else {
      next();
    }
  },
  computed: {
    planActive(): boolean {
      const plan = this.getIntervalPlanIfExists();
      return plan && "interval" in plan;
    },
    showVerificationLink(): boolean {
      const providerId = auth.currentUser?.providerData[0]?.providerId || "";

      return Boolean(
        auth.currentUser &&
          hasProviderEmailVerification(providerId) &&
          !auth.currentUser.emailVerified
      );
    }
  },
  async mounted() {
    await this.getPlan();

    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
          if (authResult.additionalUserInfo.isNewUser) {
            const props = {
              email: authResult.user.email,
              user_id: authResult.user.uid,
              created_at: moment(authResult.user.metadata.creationTime).unix()
            };

            analyticsLogEvent("user_created", props);

            const user = authResult.user;
            if (!user.emailVerified) {
              user
                .sendEmailVerification()
                .catch((error: any) => {
                  Sentry.captureException(error);
                })
                .finally(() => {
                  this.redirectToSuccessUrl();
                });
            } else {
              this.redirectToSuccessUrl();
            }

            return false;
          } else return true;
        }
      },
      signInSuccessUrl: this.$route.query.redirectPath
        ? this.$route.query.redirectPath.toString()
        : "/",
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ]
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    sendEmail() {
      if (this.sending) return;
      this.sending = true;
      auth.currentUser
        ?.sendEmailVerification()
        .then(() => {
          this.notification({
            ...notificationStatus.SUCCESS,
            message: this.$t("share.email_sent")
          });
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          this.notification({
            ...notificationStatus.ERROR,
            message: this.$t("common.error")
          });
        })
        .finally(() => (this.sending = false));
    },
    redirectToSuccessUrl() {
      window.location.assign(
        this.$route.query.redirectPath
          ? this.$route.query.redirectPath.toString()
          : "/"
      );
    },
    async getPlan() {
      const plan = this.getIntervalPlanIfExists();
      if (plan) {
        const { tp, interval, interval_count } = plan;

        try {
          const product = (await getPaymentProduct(tp || "prim", {
            interval: interval || "month",
            interval_count: Number(interval_count) || 1
          })) as ProductConfig;
          if (product) {
            const selectedPrice = product?.selectedPrice;
            const intervalValue = selectedPrice?.interval;
            const intervalCount = selectedPrice?.interval_count;
            const currency = selectedPrice?.currency;
            const price = selectedPrice?.unit_amount;

            let frequency = "";

            if (intervalValue === "year") {
              frequency =
                intervalCount === 1
                  ? `billed annually`
                  : `billed every ${intervalCount} ${intervalValue}s`;
            } else {
              frequency =
                intervalCount === 1
                  ? `billed per ${intervalValue}`
                  : `billed every ${intervalCount} ${intervalValue}s`;
            }

            this.selectedPrice = {
              price: formatPriceUnitAmount(price, currency),
              frequency,
              trialDescription: "after 14-day free trial."
            };
            this.planLoaded = true;
          }
        } catch (e) {
          this.notification({
            ...notificationStatus.ERROR,
            message: this.$t("common.error")
          });
        }
      }
    },
    getPlanTitle(interval: string) {
      const titles = {
        year: "Annual",
        month: "Monthly"
      };
      const id = interval as keyof typeof titles;
      return titles[id];
    },
    getIntervalPlanIfExists() {
      if (this.$route.query.redirectPath) {
        return getUrlParams(this.$route.query.redirectPath.toString());
      }

      return null;
    }
  }
});
