<template>
  <v-btn
    :color="color"
    :rounded="$attrs.text"
    light
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 960px) {
  .v-btn {
    min-width: 120px !important;
  }
}
.v-btn:not(.v-btn--outlined) {
  font-weight: 600;
}
.v-btn--outlined {
  border-color: var(--v-gray-lighten1);
}
</style>
