import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Auth from "@/views/Auth.vue";
import Payment from "@/views/Payments/Payment.vue";
import EarlyAccess from "@/views/Payments/EarlyAccess.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/prerender",
    name: "Prerender"
  },
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true
    },
    component: Home
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      requiresAuth: true
    },
    component: Payment
  },
  {
    path: "/early-access-signup",
    name: "EarlyAccess",
    component: EarlyAccess
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/Terms.vue")
  },
  {
    path: "/boards/:boardId",
    name: "Boards",
    props: true,
    meta: {
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "board" */ "../views/Board.vue")
  },
  {
    path: "/boards-instagram-pictures",
    name: "BoardInstagramIntegrait",
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "boardinstagram" */ "../components/Integrations/InstagramIntegartion.vue"
      )
  },
  {
    path: "/404",
    name: "NotFound",
    meta: {
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/Errors/NotFound.vue")
  },
  { path: "*", redirect: "/404" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
