var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"z-index":"8"},attrs:{"height":80,"max-width":"100vw","fixed":"","app":"","color":"background"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"5"}},[(_vm.isAuthenticated)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.boardsExist),expression:"boardsExist"}],staticClass:"mr-2"},[(_vm.editToolbarTitle && _vm.owner)?_c('BaseInput',{staticClass:"fs-mask",attrs:{"dark":"","dense":"","outlined":"","input-style":{
              width: _vm.toolbarTextWidth + 'px'
            }},on:{"change":_vm.renameBoard,"blur":function($event){_vm.editToolbarTitle = false}},model:{value:(_vm.newBoardName),callback:function ($$v) {_vm.newBoardName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newBoardName"}}):_c('v-menu',{attrs:{"offset-y":"","max-height":"300","nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"menu",staticClass:"text-body-1",staticStyle:{"font-weight":"normal !important"},attrs:{"max-width":_vm.maxTitleWidth,"outlined":"","color":"gray lighten-4"},nativeOn:{"dbclick":function($event){return _vm.editBoardName($event)}}},'v-btn',attrs,false),on),[(_vm.currentBoard)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ellipsis--text mr-2 fs-mask",on:{"click":_vm.editBoardName}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.currentBoard.name)+" ")])]}}],null,true)},[_c('span',{staticClass:"fs-mask"},[_vm._v(" "+_vm._s(_vm.$t("common.rename"))+" "+_vm._s(_vm.currentBoard.name)+" ")])]):_vm._e(),_c('Icon',{attrs:{"icon-class":"btn__select--board"}},[_vm._v(" "+_vm._s(_vm.svgChevronDown)+" ")])],1)]}}],null,false,3399516353)},[_c('Boards',{on:{"onClick":_vm.onBoardChange}})],1)],1),_c('BaseButton',{staticClass:"btn--new-board",on:{"click":function($event){return _vm.$emit('onBoardModalOpen')}}},[_c('Icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.svgPlus))]),_vm._v(" "+_vm._s(_vm.$t("board.create"))+" ")],1),(_vm.canEditBoard)?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mr-4 ml-2 btn--settings"},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":_vm.$t('common.settings'),"color":"white"}},[_vm._v(" vb-slider ")])],1)]}}],null,false,1561535305)},[_c('BoardSettings')],1):_vm._e(),(_vm.canEditBoard)?[_c('SaveIndicator')]:_vm._e()]:_vm._e()],2),_c('v-col',{staticClass:"d-flex justify-space-around align-center",attrs:{"cols":"2"}},[_c('v-img',{attrs:{"max-height":"40","contain":"","position":"center center","alt":"Visionbook","src":require("@/assets/logo-white.png")}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"5"}},[(_vm.isAuthenticated)?[(_vm.canEditBoard)?_c('BaseButton',{staticClass:"mr-4",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('onShareModalOpen')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("common.share")))])]):_vm._e(),_c('BaseButtonIcon',{staticClass:"mr-4",attrs:{"text":"","href":_vm.helpUrl,"target":"_blank"}},[_c('Icon',{attrs:{"tooltip":_vm.$t('common.help'),"color":"gray lighten-4"}},[_vm._v(" vb-inquiry ")])],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"aria-label":"Profile"}},'v-avatar',attrs,false),on),[(_vm.avatarUrl)?_c('v-img',{attrs:{"src":_vm.avatarUrl,"alt":""}}):_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.fullName.charAt(0)))])],1)]}}],null,false,1162332885)},[_c('Profile')],1)]:_c('BaseButtonIcon',{staticClass:"mr-4",attrs:{"text":""},on:{"click":_vm.login}},[_c('Icon',{attrs:{"tooltip":_vm.$t('user.login'),"color":"gray lighten-4"}},[_vm._v(" "+_vm._s(_vm.svgLogin)+" ")])],1)],2)],1):_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"3"}},[_c('BaseButtonIcon',{attrs:{"aria-label":"Menu"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toogle-drawer')}}},[(_vm.drawer)?_c('Icon',{attrs:{"color":"gray lighten-4"}},[_vm._v(" "+_vm._s(_vm.svgClose)+" ")]):_c('Icon',{attrs:{"color":"gray lighten-4"}},[_vm._v(" "+_vm._s(_vm.svgMenu)+" ")])],1),(_vm.canEditBoard)?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"ml-2 btn--settings",attrs:{"aria-label":"Settings"}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"color":"white"}},[_vm._v(" vb-slider ")])],1)]}}],null,false,774741700)},[_c('BoardSettings')],1):_vm._e(),(_vm.canEditBoard)?[_c('SaveIndicator')]:_vm._e()],2),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"width-auto",attrs:{"max-height":"40","position":"center center","contain":"","alt":"Visionbook","src":require("@/assets/logo-white.png")}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"3"}},[(_vm.isAuthenticated)?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"height":"36","width":"36","min-width":"36"}},'v-avatar',attrs,false),on),[(_vm.avatarUrl)?_c('v-img',{attrs:{"src":_vm.avatarUrl,"alt":""}}):_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.fullName.charAt(0)))])],1)]}}],null,false,34448819)},[_c('Profile')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }