import router from "@/router/index";
import store from "@/store/index";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { updateIntercom } from "@/plugins/intercom";

router.beforeEach(async (to, from, next) => {
  analyticsLogEvent(gaEventNames.page_view, {
    page_title: to.name || to.path,
    page_location: window.location.href,
    page_path: to.fullPath
  });

  updateIntercom();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    await store.dispatch("users/waitForUserLoaded");
    if (!store.getters["users/isAuthenticated"]) {
      next({ name: "Auth", query: { redirectPath: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});
