import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
    tracingOptions: {
      trackComponents: true
    },
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    release: process.env.VUE_APP_GIT_HASH,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ]
  });
}

export default Sentry;
