import { Colors } from "@/options/boardOptions";

export const DEFAULT_COLOR = "#000000DE";
export const TRANSPARENT = "#00000000";
export const WHITE = "#FFFFFFFF";
export const BACKGROUND = "#f2f5ff";

export const COLOR_SET = [
  ["#f44336ff", "#e91e63ff", "#9c27b0ff", "#673ab7ff"],
  ["#3f51b5ff", "#2196f3ff", "#03a9f4ff", "#00bcd4ff"],
  ["#009688ff", "#4caf50ff", "#8bc34aff", "#cddc39ff"],
  ["#ffeb3bff", "#ffc107ff", "#ff9800ff", DEFAULT_COLOR]
];

export const baseLightColors = Object.freeze({
  primary: "#4568dc", // brandbook "#5195ff"
  secondary: "#b06ab3", // brandbook "#b80fff"
  accent: "#58E1FC" // brandbook "#00ffff"
}) as Colors;
