import {
  PriceConfig,
  PriceParams,
  ProductConfig,
  ProductTypes
} from "@/options/paymentOptions";
import { db, functions } from "@/plugins/firebase";

// Billing portal handler
export const billingPortal = async () => {
  const functionRef = functions.httpsCallable("createPortalLink");
  const { data } = await functionRef({ returnUrl: window.location.origin });
  window.location.assign(data.url);
};

const productTypes = {
  ea: "Visionbook Early Access",
  nyr: "Visionbook New Year Resolution",
  prim: "Visionbook Premium"
} as ProductTypes;

const getProduct = async (type: string) => {
  const productKey = type as keyof ProductTypes;
  const productName = productTypes[productKey] || "Visionbook Premium";
  const productsRef = db.collection("products");
  return await productsRef
    .where("active", "==", true)
    .where("name", "==", productName)
    .get();
};

export const getPaymentProduct = async (
  productType: string,
  priceParams: PriceParams
): Promise<ProductConfig | undefined> => {
  const product = await getProduct(productType);
  const doc = product.docs[0];

  const priceSnapshot = await doc.ref
    .collection("prices")
    .where("active", "==", true)
    .where("interval", "==", priceParams.interval)
    .where("interval_count", "==", Number(priceParams.interval_count))
    .get();

  const price = priceSnapshot.docs[0];
  const priceData: PriceConfig = {
    id: price.id,
    ...price.data()
  };

  return {
    id: doc.id,
    ...doc.data(),
    prices: priceSnapshot.docs.map(x => {
      return { id: x.id, ...x.data() };
    }),
    selectedPrice: priceData
  };
};

export function getUrlParams(search: string) {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  const params: any = {};
  hashes.forEach(hash => {
    const [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
}
