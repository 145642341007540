<template>
  <base-form-group
    :validator="validations[name]"
    :label="label"
    :validator-params="validatorParams"
  >
    <template v-slot="{ errors }">
      <v-textarea
        outlined
        dense
        v-bind="{ ...attrs, ...errors }"
        :value="value"
        v-on="listeners"
      />
    </template>
  </base-form-group>
</template>
<script>
export default {
  name: "BaseTextarea",
  props: {
    name: String,
    value: {
      required: true
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    validatorParams: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    attrs() {
      return { hideDetails: "auto", ...this.$attrs };
    },
    label() {
      return this.attrs.label || this.attrs.placeholder;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: v => {
          if (this.name) {
            const validator = this.validations[this.name];
            if (validator) {
              validator.$touch();
            }
          }
          this.$emit("input", v);
        }
      };
    }
  }
};
</script>
