import Sentry from "@/plugins/sentry";

export const EMPTY_FONT_FAMILY = "Default";
export const DEFAULT_FONT_SIZE = "Default";

const DEFAULT_FONT_FAMILY_NAMES = [
  EMPTY_FONT_FAMILY,
  "Arial",
  "Arial Black",
  "Georgia",
  "Impact",
  "Tahoma",
  "Times New Roman",
  "Verdana",
  "Courier New",
  "Lucida Console",
  "Monaco",
  "monospace",
  "Roboto"
];

export const DEFAULT_FONT_FAMILY_MAP = DEFAULT_FONT_FAMILY_NAMES.reduce(
  (obj: { [key: string]: string }, type: string) => {
    obj[type] = type;
    return obj;
  },
  {}
);

const DEFAULT_FONT_SIZE_NAMES = [
  DEFAULT_FONT_SIZE,
  "10",
  "12",
  "14",
  "16",
  "18",
  "24",
  "36",
  "48",
  "64"
];

export const DEFAULT_FONT_SIZE_MAP = DEFAULT_FONT_SIZE_NAMES.reduce(
  (obj: { [key: string]: string }, type: string) => {
    obj[" " + type] = type;
    return obj;
  },
  {}
);

export const getGlobalFont = () => {
  try {
    const board = document.querySelectorAll(".v-application");
    if (board[0] && board[0] instanceof Element) {
      return getComputedStyle(board[0], null).fontFamily.replace(/['"]+/g, "");
    }
  } catch (e) {
    Sentry.captureMessage(String(e));
    return "";
  }
};
