















import Vue from "vue";
import Sentry from "@/plugins/sentry";
import { mapState, mapActions, mapGetters } from "vuex";
import { db } from "@/plugins/firebase";
import { notificationStatus } from "@/options/notificationOptions";
import { ProductConfig, SelectedPriceConfig } from "@/options/paymentOptions";
import { billingPortal, getPaymentProduct } from "@/helpers/paymentHelpers";

export default Vue.extend({
  name: "Payment",
  data() {
    return {
      title: ""
    };
  },
  computed: {
    ...mapState("users", ["stripeRole"]),
    ...mapGetters("users", ["uid"])
  },
  async mounted() {
    await this.setCustomClaimRole();
    if (this.stripeRole === "premium" || !this.$route.query.interval) {
      this.title = this.$t("payment.portal_wait") as string;
      await billingPortal();
    } else {
      this.title = this.$t("payment.checkout_wait") as string;
      await this.subscribe(await this.getProduct());
    }
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    ...mapActions("users", ["setCustomClaimRole"]),
    async getProduct(): Promise<ProductConfig | undefined> {
      try {
        const priceParams = {
          interval: String(this.$route.query.interval) || "month",
          interval_count: Number(this.$route.query.interval_count) || 1
        };

        return await getPaymentProduct(
          String(this.$route.query.tp),
          priceParams
        );
      } catch {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.$t("common.error")
        });
        return;
      }
    },
    // Checkout handler
    async subscribe(selectedProduct: ProductConfig | undefined) {
      if (!selectedProduct || !selectedProduct.selectedPrice) {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.$t("common.error")
        });
        return;
      }

      // For prices with metered billing we need to omit the quantity parameter.
      // For all other prices we set quantity to 1.
      const selected: SelectedPriceConfig = {
        price: selectedProduct.selectedPrice.id
      };
      if (selectedProduct.selectedPrice.recurring?.usage_type !== "metered") {
        selected.quantity = 1;
      }
      const checkoutSession: any = {
        collect_shipping_address: true,
        allow_promotion_codes: true,
        line_items: [selected],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          key: "value"
        }
      };
      // For one time payments set mode to payment.
      if (selectedProduct.selectedPrice.type === "one_time") {
        checkoutSession.mode = "payment";
      }

      const docRef = await db
        .collection("customers")
        .doc(this.uid)
        .collection("checkout_sessions")
        .add(checkoutSession);
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(
        async snap => {
          const session = snap.data();
          if (session) {
            const { error, url } = session;

            if (error) {
              Sentry.captureException(error.message);
              this.notification({
                ...notificationStatus.ERROR,
                message: this.$t("common.error")
              });
            }

            if (url) {
              window.location.assign(url);
            }
          }
        },
        error => Sentry.captureMessage(error.message)
      );
    }
  }
});
