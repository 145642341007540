import "@/assets/Inter.css";
import "@/assets/Icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import VuetifyConfirm from "vuetify-confirm";
import { baseLightColors } from "@/utils/color";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ...baseLightColors,
        gray: "#9a9a9a",
        background: "#091033"
        // lightGray: "#EDEDED", - replaced with var(--v-gray-lighten3) || gray lighten-3
        // background: "#FFFFFF", - replaced with var(--v-gray-lighten4) || gray lighten-4
        // backgroundDark: "#091033" - changed name for background
      }
    }
  }
});

Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
