<template>
  <v-footer dark padless color="background" height="8">
    <span
      class="text-center text-caption gray--text"
      style="bottom: 18px; position: absolute; width: 100%;"
    >
      {{ new Date().getFullYear() }} — Visionbook |
      <router-link to="/privacy">Privacy</router-link> |
      <router-link to="/terms">Terms</router-link>
    </span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
