import { Colors } from "@/options/boardOptions";
import vuetify from "@/plugins/vuetify";
import { baseLightColors } from "@/utils/color";

export const overrideDefaultColors = (colors?: Colors) => {
  vuetify.framework.theme.currentTheme.primary =
    colors?.primary || baseLightColors.primary;
  vuetify.framework.theme.currentTheme.secondary =
    colors?.secondary || baseLightColors.secondary;
  vuetify.framework.theme.currentTheme.accent =
    colors?.accent || baseLightColors.accent;
};

export const getCurrentThemeColors = (): Colors => {
  return {
    primary: vuetify.framework.theme.currentTheme.primary,
    secondary: vuetify.framework.theme.currentTheme.secondary,
    accent: vuetify.framework.theme.currentTheme.accent
  } as Colors;
};

export const overrideDefaultFontFamily = (font?: string) => {
  const elements = Array.from(
    document.getElementsByClassName("v-application") as HTMLCollectionOf<
      HTMLElement
    >
  );

  elements.forEach((element: HTMLElement) => {
    element.style.setProperty(
      "font-family",
      font && font !== "Apercu" ? font : "Inter",
      "important"
    );
  });
};
