<template>
  <div>
    <v-checkbox
      color="primary"
      off-icon="vb-checkmark"
      on-icon="vb-checkmark-filled"
      :input-value="value"
      v-bind="$attrs"
      v-on="listeners"
    >
      <template v-slot:label>
        <label class="text-body-1">
          <slot></slot>
        </label>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "BaseCheckboxLabel",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: v => {
          this.$emit("input", v);
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--checkbox {
  padding-top: 0px !important;
}
</style>
