import { Notification } from "@/store/types";

export const notificationStatus = {
  SUCCESS: {
    color: "success",
    timeout: 3000,
    message: "Success"
  } as Notification,
  ERROR: { color: "error", timeout: 3000, message: "Error" } as Notification,
  INFO: { color: "info", timeout: 3000, message: "Info" } as Notification,
  WARNING: {
    color: "warning",
    timeout: 3000,
    message: "Warning"
  } as Notification
};

export type NotificationStatusType = keyof typeof notificationStatus;
