import firebase from "firebase";
import moment from "moment";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentData = firebase.firestore.DocumentData;
import { UserSettings } from "@/store/types";

export const emailVerifiableProviders = ["password"];

export const hasProviderEmailVerification = (providerId: string) => {
  return emailVerifiableProviders.includes(providerId);
};

export const requiresEmailVerification = (user: firebase.User) => {
  const { creationTime } = user.metadata;
  const now = moment(new Date());
  const daysFromAccountCreation = now.diff(moment(creationTime), "days");
  const providerId = user?.providerData[0]?.providerId || "";

  return (
    hasProviderEmailVerification(providerId) && // provider requires verification
    daysFromAccountCreation > 7 && // account old enough to need verification
    !user.emailVerified
  );
};

/* anyone has a better name? ;) */
export const fbqPurchaseEventAlreadySent = (
  userData: DocumentSnapshot<DocumentData>
) => {
  if (!userData.exists) return false;

  const userDt = userData.data() as UserSettings;

  return "fbqPurchaseEventSent" in userDt && userDt.fbqPurchaseEventSent;
};
