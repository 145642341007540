<template>
  <v-select
    color="primary"
    item-color="primary"
    :append-icon="svgMenuDown"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import { mdiMenuDown } from "@mdi/js";

export default {
  name: "BaseSelect",
  data: () => ({
    svgMenuDown: mdiMenuDown
  })
};
</script>
