



















import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store/index";

export default Vue.extend({
  name: "Home",
  computed: {
    ...mapState("boards", ["boards", "createInProgress"])
  },
  watch: {
    "boards.length"(val) {
      if (val > 0 && !this.createInProgress) {
        const nextPath = `/boards/${this.boards[0].id}`;
        if (this.$route.path !== nextPath) this.$router.push(nextPath);
      }
    }
  },
  methods: {
    openBoardModal() {
      store.dispatch("boards/openBoardForm");
    }
  }
});
