<template>
  <v-tooltip bottom :disabled="!tooltip || $vuetify.breakpoint.smAndDown">
    <template v-slot:activator="{ on }">
      <v-icon
        :style="iconStyle"
        :color="color"
        v-bind="$attrs"
        :class="iconClass"
        v-on="on"
      >
        <slot></slot>
      </v-icon>
    </template>
    <span v-if="!$vuetify.breakpoint.smAndDown">{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "Icon",
  props: {
    color: {
      type: String,
      required: false
    },
    tooltip: {
      type: String
    },
    styles: {
      type: [Object]
    },
    iconClass: {
      type: [String]
    }
  },
  computed: {
    iconStyle() {
      return typeof this.styles === "object" ? this.styles : {};
    }
  }
};
</script>
