<template>
  <v-snackbars :objects.sync="objects" transition="scroll-y-transition">
    <template v-slot:action="{ close }">
      <BaseButtonIcon @click="close()">
        <Icon color="white">{{ svgClose }}</Icon>
      </BaseButtonIcon>
    </template>
  </v-snackbars>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mdiClose } from "@mdi/js";
import VSnackbars from "v-snackbars";

export default {
  name: "Notification",
  components: {
    "v-snackbars": VSnackbars
  },
  data() {
    return {
      svgClose: mdiClose
    };
  },
  computed: {
    ...mapState("notifications", ["notifications"]),
    objects: {
      get() {
        return this.notifications;
      },
      set(value) {
        this.RESET_NOTIFICATIONS();
      }
    }
  },
  methods: {
    ...mapMutations("notifications", ["RESET_NOTIFICATIONS"])
  }
};
</script>
